/**
 * The inline media card is used to display events.
 * It is a media object that contains an image on the left hand side.
 * If no image is provided, it will fallback to a date
 */

import React from "react"
import { Link } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { GatsbyImage } from "gatsby-plugin-image"
import { sanityConfig } from "../../utils/sanity"

export const InlineMediaCard = ({
  imageSrc,
  title,
  subTitle,
  meta,
  fallbackText,
  featureImage,
  to,
}) => {
  const image = featureImage
    ? getGatsbyImageData(
        featureImage.asset.id,
        { maxWidth: 1024 },
        sanityConfig
      )
    : null

  return (
    <Link
      to={to}
      className="flex shadow-sm rounded-md transition hover:shadow-lg group"
    >
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
        {fallbackText ? (
          <div className="flex-shrink-0 flex items-center justify-center w-24 p-4 bg-gray-400 transition group-hover:bg-indigo-900 text-white text-sm font-medium rounded-l-md">
            <span className="flex items-center justify-center rounded-full w-16 h-16 border-4 border-white text-white font-extrabold text-lg p-4">
              {fallbackText}
            </span>
          </div>
        ) : (
          <div className="flex-shrink-0 w-32 rounded-l-md h-full">
            {image ? (
              <GatsbyImage
                className="object-cover w-full h-full rounded-l"
                image={image}
              />
            ) : (
              <img
                className="object-cover w-full h-full rounded-l"
                src="https://www.uniquevenuesoflondon.co.uk/sites/default/files/cover/Royal%20Geographical%20Society%20-%20Stills%20-%20Web%20Quality%20-24_0.jpg"
                alt=""
              />
            )}
          </div>
        )}

        <div className="flex-1 px-4 text-sm py-2">
          {meta && (
            <p className="text-gray-500 text-sm font-semibold mb-1">{meta}</p>
          )}

          <h3 className="serif fontb-bold text-lg xl:text-xl">{title}</h3>

          {subTitle && <p className="text-gray-500 mt-2">{subTitle}</p>}
        </div>
      </div>
    </Link>
  )
}
