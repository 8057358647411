import React from "react"
import { graphql, Link } from "gatsby"

import Banner from "../components/banner"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { InlineMediaCard } from "../components/media-card/InlineMediaCard"

const EventsPage = ({ data }) => {
  const { currentEvents, pastEvents } = data

  // Get the first event
  const firstEvent = currentEvents.edges[0]
  const remainingEvents = currentEvents.edges.filter((_, index) => index > 0)

  return (
    <>
      <Seo title="Events" />
      <Layout>
        <section id="heroFeaturedEvent">
          {currentEvents.edges.length > 0 && firstEvent ? (
            <div className="z-0 overflow-hidden sm:aspect-w-16 sm:aspect-h-9 md:aspect-h-7 xl:aspect-h-5 2xl:aspect-h-4">
              {firstEvent?.node?.featureImage ? (
                <img
                  className="hidden sm:block object-cover"
                  src={firstEvent.node.featureImage.asset.url}
                  alt={firstEvent.node.featureImage.asset.altText}
                />
              ) : (
                <img
                  className="hidden sm:block object-cover"
                  src="https://www.uniquevenuesoflondon.co.uk/sites/default/files/cover/Royal%20Geographical%20Society%20-%20Stills%20-%20Web%20Quality%20-24_0.jpg"
                  alt="Auditorium"
                />
              )}
              <div className="container mx-auto h-full sm:flex items-center">
                <div className="max-w-2xl bg-indigo-900 text-white lg:shadow-xl w-full p-6 md:p-8">
                  <h1 className="serif font-extrabold text-xl lg:text-4xl">
                    {firstEvent.node.title}
                  </h1>
                  <span className="block my-5 font-bold">
                    {firstEvent.node.fullDate}
                  </span>
                  <p className="prose lg:prose-lg text-white mb-5 mx-1">
                    {firstEvent.node.excerpt}
                  </p>

                  <Link
                    to={`/events/${firstEvent.node.slug.current}`}
                    className="inline-block transition bg-indigo-700 hover:bg-indigo-600 px-5 py-3 rounded font-semibold"
                  >
                    {firstEvent.node.bookingUrl
                      ? "Book Ticket Now"
                      : "Read More"}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <Banner>Events</Banner>
          )}
        </section>

        <section className="z-10 sm:absolute flex items-center top-0 left-0" />

        {remainingEvents && remainingEvents.length > 0 && (
          <section id="upcomingEvents" className="bg-gray-100 py-16">
            <div className="container mx-auto px-3">
              <h2 className="text-gray-700 text-xl lg:text-3xl serif font-extrabold tracking-wide">
                Upcoming Events
              </h2>

              <ul className="grid lg:grid-cols-2 gap-5 sm:gap-6 xl:gap-x-16 xl:gap-y-8 mt-8">
                {remainingEvents.map((event) => {
                  const { node } = event

                  return (
                    <li key={node.id}>
                      <InlineMediaCard
                        to={`/events/${node.slug.current}`}
                        title={node.title}
                        meta={node.eventStart}
                        subTitle={node.venue}
                        featureImage={node.featureImage}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        )}

        {pastEvents && pastEvents.edges.length > 0 && (
          <section id="pastEvents">
            <div className="container mx-auto px-3 py-8">
              <h2 className="text-gray-700 lg:text-2xl serif font-extrabold tracking-wide">
                Past Events
              </h2>

              <ul className="mt-6 grid grid-cols-1 gap-5 sm:gap-6 xl:gap-x-16 xl:gap-y-8 lg:grid-cols-2">
                {pastEvents.edges.map((event) => {
                  const { node } = event

                  return (
                    <li>
                      <InlineMediaCard
                        to={`/events/${node.slug.current}`}
                        meta={node.eventStart}
                        fallbackText={node.year}
                        title={node.title}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        )}
      </Layout>
    </>
  )
}

export default EventsPage

export const query = graphql`
  query {
    currentEvents: allSanityEvent(
      filter: { isPastEvent: { eq: false } }
      sort: { fields: eventStart, order: ASC }
    ) {
      edges {
        node {
          bookingUrl
          excerpt
          id
          title
          eventStart(formatString: "MMM Do YYYY")
          fullDate: eventStart(formatString: "dddd, Do MMMM YYYY")
          venue
          featureImage {
            asset {
              id
              altText
              url
            }
          }
          slug {
            current
          }
        }
      }
    }

    pastEvents: allSanityEvent(
      filter: { isPastEvent: { eq: true } }
      sort: { fields: eventStart, order: DESC }
    ) {
      edges {
        node {
          id
          title
          eventStart(formatString: "MMM Do YYYY")
          year: eventStart(formatString: "YYYY")
          venue
          slug {
            current
          }
        }
      }
    }
  }
`
